/* background dark */
.Item-section-dark{
 height: fit-content;
 width: calc(100% - 2*56px);
 padding: 18px 56px;
 background-color: teal;
 /*background-image: url('./section-green-bg.png'); 
 background-size: contain;
 background-position: right top;
 background-repeat: repeat; */
 display: flex;
 flex-wrap: wrap;
 align-items: space-between;
}

/* background light */
.Item-section-light{
  height: fit-content;
  width: calc(100% - 2*56px);
  padding: 18px 56px;
  background-color: white;
  /* background-image: url('./section-light-bg.png');
  background-size: contain;
  background-position: right top;
  background-repeat: repeat; */
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
 }

/* color dark */
.dark{
  color: black;
}

/* color light */
.light{
  color: white;
}

/* brand design*/
.Brand-section{
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-left: 36px;
}
.Brand-icon{
  width: 76px;
  height: 64px;
}
.Brand-text{
  margin-left: 16px;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  color: white;
}
.Plant-image{
  height: 75vh;
  margin: 24px 0 48px 0;
 /*  border-radius: 20px;
 /*  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); Add shadow to enhance the depth effect */
}

.Item-left{
  flex: 50%;
  margin: auto;
}
.Item-right{
  flex: 50%;
  text-align: center;
}

/* first section design */
.Section-header{
  margin: 0 0 64px 0;
  font-size: 56px;
  line-height: 1.4;
  font-weight: bold;
  color: white;
}
.Section-body{
  font-size: 32px;
  font-weight: normal;
  color: white;
}

/* other sections design */
.Section2-header{
  width: 100%;
  margin: 28px auto 12px auto;
  font-size: 48px;
  line-height: 1;
  font-weight: 350;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Section2-sub-header{
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 350;
}
.Section2-body{
  padding: 8px 56px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  font-weight: normal;
}


.Item-collection{
  width: 100%;
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  
}
.Item-box{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 424px;
  height: 300px;
  background-color: #DDF6F6;
  margin: 12px auto;
  border-radius: 6px;
}
.Item-box > img{
  width: 424px;
  height: 300px;
  border-radius: 6px;
}

.card{
  height: 480px;
  width: 414px;
  border-radius: 6px;
  position: relative;
  margin: 24px 0;

}
.card >img{
  height: 480px;
  width: 414px;
  border-radius: 6px;
  z-index: 1;
}

.static{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 480px;
  width: 414px;
}

.overlay {
  border-radius: 6px;
  opacity: 0.74;
  -webkit-opacity:0.74;
  -khtml-opacity: 0.74;
  background-color: white;
  z-index: 2;
}
.card-body{
  padding: auto;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.card-body-header{
  position: relative;
  width: 100%;
  margin: 0px auto 12px auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 350;
  color: black;
}
.card-body-text{
  position: relative;
  padding: 0px 36px;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  color: black;
}

.download-layout{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-layout > a{
  margin: 24px 12px;
}
.footer a{
  text-decoration: none;
  color: black;
}
.footer a:hover{
  color: grey;
}
.footer a:focus, .footer .active{
  color: teal;
}
.footer{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.footer-text{
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  color: black;

}
.footer-bold{
  margin: 24px 0px 0px 12px;
  font-size: 18px;
  font-weight: bold;
  color: black;
}
.footer-text>a{
  text-decoration: none;
  color: black;
}



@media screen and (max-width: 800px) {
  .Item-section-dark,  .Item-section-light{
   height: fit-content;
   width: calc(100% - 2*24px);
   padding: 24px;
   text-align: center;
  }

  .Brand-section{
    display: flex;
    flex-direction: row;
    margin: 12px auto 56px auto;
    justify-content: center;
  }

  .Section-header{
    margin: 0 0 24px 0;
    font-size: 32px;
    line-height: 1;
    font-weight: bold;
    color: white;
  }

  .Item-section-dark{
    height: fit-content;
  }

  
  .Item-left{
    width: 100%;
    order: 1;
  }
  .Item-right{
    width: 100%;
    order: 2;
  }
  
  .Plant-image{
    text-align: center;
    width: 100%;
    height: 400px;
    margin: 48px auto;
  }
 
  .Section2-body{
    padding: 8px 16px;
  }
  
  .Item-box{
    text-align: center;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }


  .Item-box > img{
    width: 100%;
    height: 300px;
    border-radius: 6px;
  }
  
  .card{
    width: 100%;
    height: 450px;
    border-radius: 6px;
    position: relative;
    margin: 24px auto;
  
  }

  .card >img{
    width: 100%;
    height: 450px;
    border-radius: 6px;
    z-index: 1;
  }
  
  .static{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 450px;
  }

}