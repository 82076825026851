body{
  background-color: teal;
}
.privacy .section{
  padding: 18px 56px;
  color: white;
}
.privacy .title{
 font-weight: bold;
}

.privacy .link{
  text-decoration: none;
  color: white;
}
.privacy .link:hover, .privacy .link:focus{
  color: wheat;
}

/* brand design*/
.privacy .Brand-section{
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}
.privacy .Brand-icon{
  width: 76px;
  height: 64px;
}
.privacy .Brand-text{
  margin: 20px 0;
  margin-left: 24px;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  color: white;
}



@media (max-width: 800px) {
  .privacy .section{
    padding: 24px;
    color: white;
  }
}